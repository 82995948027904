import React, { createContext, useContext, useState } from 'react';
import { registerRequest } from '../api/auth';

export const AuthContext = createContext();

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

// Proveedor de autenticación
export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errors, setErrors] = useState([]);

    const signup = async (user) => {
        try {
            const res = await registerRequest(user);
            setUser(res.data); 
            setIsAuthenticated(true);
            console.log('Registro exitoso:', res.data);
        } catch (error) {
            console.error('Error en el registro:', error.response ? error.response.data : error.message);
            setErrors(error.response.data)
            setIsAuthenticated(false);
        }
    };

    return (
        <AuthContext.Provider value={{ signup, user, isAuthenticated, errors }}>
            {children}
        </AuthContext.Provider>
    );
}
