import axios from 'axios';

const API_URL = 'http://165.227.86.243:5000/api'; // Cambia la URL según tu configuración

// Función para registrar un nuevo usuario
export const registerRequest = (user) => axios.post(`${API_URL}/register`, user);

// Ejemplo de cómo usar registerRequest en una función asíncrona
const handleRegister = async (values) => {
    try {
        const res = await registerRequest(values);
        console.log('Registro exitoso:', res.data);
    } catch (error) {
        console.error('Error en el registro:', error.response ? error.response.data : error.message);
    }
};