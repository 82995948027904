import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';

export default function App() {
    return (
        <AuthProvider> 
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<h1>Home Page</h1>}></Route>
                    <Route path='/login' element={<LoginPage/>}></Route>
                    <Route path='/register' element={<RegisterPage/>}></Route>
                    <Route path='/tasks' element={<h1>Task page</h1>}></Route>
                    <Route path='/add-tasks' element={<h1>New Task</h1>}></Route>
                    <Route path='/tasks/:id' element={<h1>Update Taks</h1>}></Route>
                    <Route path='/profile' element={<h1>Profile</h1>}></Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}
